<template>
  <div class="row">
    <b-card no-body class="overflow-hidden col-md-6" style="max-width: 540px;" v-for="(project, index) in projects" :key="index">
    <b-row no-gutters>
      <b-col md="6" style="padding:20px">
        <b-card-img :src="project.logo_image" width="100%" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body :title="project.header">
          
          <b-card-text v-if="project.target[0].title">
            <div v-for="(target,targIndex) in project.target" :key="targIndex">
              {{target.title}}
              Target : {{target.number}}
              <b-row class="vertical-center">
                
                <b-col md="7"><label for="" >Achievement : </label></b-col>
                <b-col md="5" ><b-input v-model="projects[index].target[targIndex].archived"  type="number"></b-input></b-col>
              
              </b-row>
              
            </div>
            <br>
           

          </b-card-text>
      
            <div class="float-right" v-if="project.target[0].title">
                <b-button  @click="changeAcomplishment(projects[index])" variant="success"
              >Change</b-button
            ></div>
        
            <div class="float-left">
                <b-button  @click="changeVisibility(projects[index],index)" v-bind:class="{'btn-success': project.dashboard_status , 'btn-danger' : !project.dashboard_status}"
              ><span v-if="project.dashboard_status">Visible</span> <span v-if="!project.dashboard_status">Hidden</span></b-button
            ></div>
         
           
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
    
    
  </div>
</template>
<script>
import Vue from "vue";
import { getFields, pathField, pathFieldWithOutId } from "../../assets/js/service";
export default {
  data() {
    return {
      projects: null,
    };
  },
  mounted() {
    let token = localStorage.getItem("token");
    getFields("Projects", token).then((resp) => {
      this.projects = resp.data;
    });
  },
  methods: {
    changeVisibility(project,index){
        let token = localStorage.getItem("token");
        let data = {dashboard_status : !project.dashboard_status}
        pathField("Projects",project.id,data,token).then(resp=>{
          
          this.projects[index].dashboard_status = resp.data.dashboard_status;
       
           Vue.$toast.success("Successful", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
     
        })
    },
    changeAcomplishment(Project) {
      let token = localStorage.getItem("token");
      pathFieldWithOutId("Projects", Project, token).then((resp) => {
        Vue.$toast.success("Successful", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      });
    },
  },
};
</script>
<style>
</style>